  .About-text{
    text-align: center;
}
  
.p-heading1{
    font-size: 4rem;
    font-family: 'Sulphur Point', sans-serif;
}
  
.skill-p-heading2{
    text-align: left;
    font-size: 0.98rem;
    padding: 2.5%;
    font-family: 'Roboto Mono', monospace;
}

.skill-text {
    margin-bottom: 15px;
    text-align: left;
}

@media only screen and (max-width: 580px) {
    .skill-text {
        margin-bottom: 15px;
        text-align: left;
        margin-left: 15%;
    }
}