@import url('https://fonts.googleapis.com/css?family=Raleway+Dots&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css?family=Abel|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');

.Contact-form{
    text-align: center;
    padding-bottom: 8%;
  }

.contact-form-header{
    font-size: 2.5rem;
    padding: 1%;
    font-family: 'Raleway', sans-serif;
   }

.contact_input{
   padding-left: 10%;
   padding-right: 10%;
  }

.contact_input_text{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.5rem!important;
    border-radius: 5%;
    text-align: center;
    background: transparent!important;
    color: white!important;
    min-height: 6.5vh;
    resize: none;
  }
   

.contact_input_text:focus{
    background: white !important;
    color: black !important;
}

.contact_success_modal_body{
    text-align: center;
    background: #090909!important;
    color: aliceblue!important;
   }
   
.contact_success_modal_img{
     height: 15vh;
     padding-bottom: 3%;
   }
 
.contact-email-text-btn:hover{
      background: #666666!important;
      color: aliceblue!important;
  }
 