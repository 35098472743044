@import url('https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');
@import url('https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Roboto+Mono|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');

.card_col{
    font-family: 'Sulphur Point', sans-serif;
    padding-left: 4%;
    padding-top: 4%;
    width: 100%;
}

.card_main {
    margin-left: 5%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.20) !important;
    color: white;
    border-color: white;
    text-align: left;
}

.card_main:hover{
    transition: all 0.2s ease-out;
    top: -4px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0px 4px 35px rgb(255, 255, 255);
}

.blockquote-footer{
    color: white !important;
}

.description {
    font-size: 18px;
}

.project-img{
    color: white;
    display: block;
}

.footer-links {
    font-size: 18px;
}