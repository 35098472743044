.About-text{
    text-align: center;
}
  
.p-heading1{
    font-size: 4rem;
    font-family: 'Sulphur Point', sans-serif;
}
  
.exp-p-heading2{
    text-align: left;
    font-size: 0.98rem;
    padding: 2.5%;
    font-family: 'Roboto Mono', monospace;
}

.experience-img{
    height: 12vh;
    color: white;
    margin: auto;    
    display: block;
}

@media only screen and (max-width: 800px) {
    .exp-p-heading2{
        margin-left: 15px;
        margin-right: 15px
    }
}