.About-header {
    background-color:#090909;
    color: white;
}
  
.About-main {
    min-height: 95vh;
}
  
.Hamburger-menu{
    min-height: 7vh; 
    margin-bottom: 20px;
}

.d1{
    height: 100%;
    width: 100%;
}
  
.d2{
    height: 100%;
    width: 100%;
}
  
.d3{
    height: 100%;
    width: 100%;
}
  
.About-footer {
    margin-top: 20px;
    min-height: 5vh;
}

.About-Col-main {
    min-height: 88vh; 
}

.About-col{
    margin: auto;    
    display: block;
}
  
.about-left-footer{
    width: 100%;
    font-size: 1.7rem;
}
  
.button-main{
    text-align: center;
    margin-left: 30px;
}

.topic-btn {
    margin-bottom: 10px;
}

.topic-btn:hover{
     background: #666666;
}

.resume-btn{
    visibility: visible;
}

.about_resume_modal_body{
    width: 700px;
    height: 580px;
    text-align: center;
    background: #090909;
    color: aliceblue;
}

#pdfviewer {
    width: 650px;
    height: 550px;
    margin: auto;
}

#pdfviewer .viewer-layout-toolbar {
    background: #000;
    color: #fff;
}
  
#pdfviewer .viewer-icon{
    stroke: #fff;
    stroke-width: 3px;
}
  
#pdfviewer .viewer-zoom-popover-target-arrow {
    border-color: rgb(255 255 255 / 90%) transparent transparent;
}
  
#pdfviewer .viewer-menu-item-label, #pdfviewer .viewer-search-popover-label, #pdfviewer .viewer-menu, #pdfviewer .viewer-menu .viewer-icon{
    color: #000;
    stroke: #000;
}

#pdfviewer .viewer-toolbar-left {
    display: none;
}

@media only screen and (max-width: 1000px) {
    .resume-btn{
        visibility: hidden;
    }

    #pdfviewer {
        display: none;
    }

    .about_resume_modal_body{
        display: none;
    }
}